import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = { class: "field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      "data-test": "newCustomer",
      label: "New Customer",
      icon: "pi pi-plus",
      class: "p-button-primary mr-2",
      style: {"float":"right"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNew()))
    }),
    _createVNode(_component_Dialog, {
      visible: _ctx.newCustDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newCustDialog) = $event)),
      style: { width: '450px' },
      header: "Customer Details",
      modal: true,
      class: "p-fluid"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          ref: "dismiss",
          label: "Cancel",
          icon: "pi pi-times",
          class: "p-button-text",
          onClick: _ctx.hideDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          ref: "save",
          label: "Save",
          icon: "pi pi-check",
          loading: _ctx.isLoading,
          class: "p-button-text",
          onClick: _ctx.saveCustInfo
        }, null, 8, ["loading", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[4] || (_cache[4] = _createElementVNode("label", { for: "name" }, "Name", -1)),
          _createVNode(_component_InputText, {
            id: "name",
            modelValue: this.customer.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.customer.name) = $event)),
            required: "true",
            autofocus: "",
            class: _normalizeClass({ 'p-invalid': _ctx.isSubmitted && !_ctx.customer.name })
          }, null, 8, ["modelValue", "class"]),
          (_ctx.isSubmitted && !_ctx.customer.name)
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, "Name is required."))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "phone" }, "Phone", -1)),
          _createVNode(_component_InputText, {
            id: "phone",
            modelValue: _ctx.customer.phone,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.phone) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}