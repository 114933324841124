
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { mapState } from "vuex";

import store from "@/store";
import { defineComponent } from "@vue/runtime-core";

import Customer from "@/types/customer";

export default defineComponent({
  components: {
    Button,
    Dialog,
    InputText,
  },
  computed: {
    ...mapState(["customerInquiry", "session"]),
  },
  data() {
    return {
      newCustDialog: false,
      isSubmitted: false,
      isLoading: false,
      customer: {} as Customer,
    };
  },
  methods: {
    openNew() {
      this.newCustDialog = true;
      this.customer = {} as Customer;
    },
    hideDialog() {
      this.newCustDialog = false;
      this.isSubmitted = false;
    },
    saveCustInfo() {
      this.isSubmitted = true;

      if (!this.customer.name?.trim()) return;

      let newCust = {
        cust_id: "",
        name: this.customer.name,
        phone: this.customer.phone,
      };

      this.isLoading = true;
      store
        .dispatch("customerInquiry/newCustomer", {
          newCust,
        })
        .then(() => {
          store.dispatch("notification/add", {
            type: "success",
            message: `New customer created!`,
          });
          this.newCustDialog = false;
        })
        .finally(() => {
          this.isLoading = false;
          this.isSubmitted = false;
        });
    },
  },
});
